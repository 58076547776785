<template>
  <div
    class="full-height full-width d-flex flex-column"
    style="position: relative"
  >
    <div id="search-container">
      <div :class="{ 'pa-2': !isMobile }">
        <v-card
          solo
          tile
          :class="{ 'rounded-lg': !isMobile }"
          id="search-input-container"
        >
          <SearchInput
            :loading="c_loading"
            :class="{ 'pa-2': isMobile }"
            :solo="!isMobile"
            :outlined="isMobile"
            :search.sync="model"
            @click:select="onSelectSearch"
            @input:enter="onComplexSearch()"
            @click:clear="$emit('click:clear')"
            attach="#search-input-container"
            :location="location"
          >
          </SearchInput>
          <v-divider v-if="isMobile"></v-divider>
          <slot v-else name="extra-search"></slot>
          <div v-if="showSelectComplex">
            <v-hover v-slot="{ hover }">
              <div class="px-4 py-3" @click="onComplexSearch">
                <div
                  class="clickable text-subtitle-2 font-weight-regular"
                  :style="{
                    color: hover ? '#000' : 'rgba(0, 0, 0, 0.54)',
                  }"
                >
                  <v-icon
                    :style="{
                      color: hover ? '#000' : 'rgba(0, 0, 0, 0.54)',
                    }"
                  >
                    mdi-chevron-down
                  </v-icon>
                  Tìm theo khu vực
                </div>
              </div>
            </v-hover>
          </div>
        </v-card>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import SearchInput from "./SearchInput.vue";
export default {
  components: {
    SearchInput,
  },
  props: {
    loading: Boolean,
    value: { type: String },
    location: {},
    showSelectComplex: Boolean,
  },
  data: () => ({}),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    c_loading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      },
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onSelectSearch(e) {
      this.$emit("click:select", e);
    },
    onResetSearch() {
      this.$emit("click:reset");
    },
    onComplexSearch() {
      this.$emit("click:complex-search", this.params);
    },
  },
};
</script>

<style scoped>
#search-container {
  position: absolute;
  z-index: 1;
  width: 100%;
}
</style>
