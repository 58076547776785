<template>
  <component
    :is="componentType"
    ref="input"
    v-click-outside="onBlurSearch"
    v-bind="bindComponents"
    dense
    hide-details
    :loading="isLoading"
    placeholder="Tìm kiếm"
    single-line
    v-on="listenersComponents"
    @focus="onFocusSearch"
    @keyup.enter="onEnter()"
  >
    <template v-slot:no-data>
      <v-list-item v-if="isLoading">
        <v-list-item-content>
          <v-list-item-title> Đang tìm kiếm... </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #prepend-inner>
      <slot name="prepend-inner">
        <v-icon color="#C0C0C0" @click="onEnter()">mdi-magnify</v-icon>
      </slot>
    </template>
    <template #append>
      <slot name="append">
        <v-icon v-if="isLoading">mdi-loading mdi-spin</v-icon>
        <v-icon
          class="primary-hover"
          v-else
          @click="onClear()"
          aria-label="close"
        >
          mdi-close
        </v-icon>
      </slot>
    </template>
    <template #item="{ item }">
      <template v-if="item.type == 'search'">
        <v-list-item-icon class="mr-2">
          <v-icon>mdi-map-marker-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content @click.stop="onSelectSearch(item.data)">
          <v-list-item-title :title="item.data.full_name || item.data.name">
            <span
              class="font-weight-regular d-inline-block pr-1"
              v-html="
                boldString(item.data.full_name || item.data.name, p_search)
              "
            ></span>
            <span class="text-caption secondary--text">
              <span v-if="item.data.commune_name"
                >{{ item.data.commune_name }}
              </span>
              <span v-if="item.data.commune_name && item.data.district_name">
                ,
              </span>
              <span v-if="item.data.district_name"
                >{{ item.data.district_name }}
              </span>
              <span v-if="item.data.district_name && item.data.province_name">
                ,
              </span>
              <span v-if="item.data.province_name"
                >{{ item.data.province_name }}
              </span>
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-else-if="item.type == 'location'">
        <v-list-item-icon class="mr-2">
          <v-icon>mdi-map-marker-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content @click.stop="onSelectLocation(item.data)">
          <v-list-item-title :title="item.data.full_name || item.data.name">
            <span
              class="font-weight-regular d-inline-block pr-1"
              v-html="
                boldString(item.data.full_name || item.data.name, p_search)
              "
            ></span>
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-else-if="item.type == 'suggerst'">
        <template v-if="item.data.type == 'result'">
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-clock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content
            @click.stop="onSelectSearchFromSuggerst(item.data)"
          >
            <v-list-item-title
              class="font-weight-regular"
              v-html="boldString(item.data.data.name, p_search)"
            >
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-clock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content
            @click.stop="onSelectSearchFromSuggerst(item.data)"
          >
            <v-list-item-title
              class="font-weight-regular"
              v-html="boldString(item.data, p_search)"
            >
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </template>
  </component>
</template>

<script>
import HistorySearch from "@service/model/HistorySearch";
import { debounce } from "@/utils/Debounce";
import { API_SEARCH } from "@/service/api";
export default {
  props: {
    search: String,
    api: {
      type: Function,
      default: (params) => API_SEARCH(params).then((res) => res.list),
    },
    maxLength: { type: Number, default: 5 },
    loading: Boolean,
    location: {},
  },
  data: () => ({
    itemsSearch: [],
    suggersts: [],
    p_loading: false,
    p_search: "",
    othersuggersts: [],
    isFocus: false,
    autofocus: true,
    isShowText: false,
    p_model: null,
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    bindComponents() {
      let dataReturn = {};
      if (this.isShowText) {
        dataReturn = { value: this.p_search };
      } else {
        dataReturn = {
          "item-text": "type",
          value: this.p_model,
          "search-input": this.p_search,
          "menu-props": { closeOnClick: false, closeOnContentClick: true },
          "no-filter": true,
          "no-data-text": "Không tìm thấy kết quả",
          "hide-no-data": !this.p_search || !this.isFocus,
          "allow-overflow": false,
          items: this.itemsShow,
        };
      }
      return { ...dataReturn, ...this.$attrs };
    },
    listenersComponents() {
      let dataReturn = {};
      if (this.isShowText) {
        dataReturn = {};
      } else {
        dataReturn = {
          "update:search-input": (e) => (this.p_search = e),
          input: (e) => this.onSelectItem(e),
        };
      }
      return dataReturn;
    },
    componentType() {
      if (this.isShowText) return "v-text-field";
      return "v-combobox";
    },
    itemsShow() {
      if (this.loading) return [];
      return this.othersuggersts
        .concat(this.itemsSearch.map((x) => ({ type: "search", data: x })))
        .concat(
          this.suggerstsFilter.map((x) => ({ type: "suggerst", data: x }))
        )

        .slice(0, this.maxLength);
    },
    suggerstsFilter() {
      let suggersts = this.suggersts;
      if (this.p_search) {
        suggersts = this.suggersts.filter((x) =>
          HistorySearch.checkByString(x, this.p_search)
        );
      }

      return suggersts;
    },
    isLoading() {
      return this.loading || this.p_loading;
    },
    model: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit("update:search", value);
      },
    },
  },
  watch: {
    search: {
      handler(value) {
        this.p_search = value;
      },
      immediate: true,
    },
    p_search: {
      handler: debounce(function (value) {
        this.othersuggersts = [];
        this.onUpdateSuggerts();
        if (!value) {
          this.p_loading = false;
          this.itemsSearch = [];
          return;
        }
        this.fetchData();
      }, 200),
      immediate: true,
    },
  },
  mounted() {
    this.onUpdateSuggerts();
    let autocompleteInput = this.$refs.input.$refs.input;

    autocompleteInput.addEventListener("focus", this.onFocus, true);
  },
  methods: {
    onFocusSearch() {
      this.isFocus = true;
      this.isShowText = false;
    },
    onFocus(e) {
      this.isFocus = true;
      this.$emit("click:focus", true);
      this.onUpdateSuggerts();
      this.$refs.input.isMenuActive = false; // open item list
      this.$nextTick(() => {
        this.$refs.input.activateMenu();
      });
    },
    onBlurSearch(e) {
      this.isFocus = false;
      this.$emit("click:focus", false);
    },
    boldString(str, substr) {
      if (!substr) return str;
      return str.replace(RegExp(substr, "gi"), `<b>$&</b>`);
    },
    onCancelSearch() {
      this.onClear();
    },
    onUpdateSuggerts() {
      this.suggersts = [...HistorySearch.searchs].reverse();
    },
    fetchData() {
      this.p_loading = true;
      let params = {
        search: this.p_search,
      };
      params.limit = 5;
      this.api(params)
        .then((items) => {
          this.itemsSearch = items;
        })
        .finally(() => {
          this.p_loading = false;
        });
    },
    onEnter(e) {
      this.model = this.p_search;
      HistorySearch.addSearch(this.p_search);
      this.isShowText = true;
      this.$emit("input:enter");
    },
    onClear() {
      this.p_search = "";
      this.model = "";
      this.itemsSearch = [];
      this.suggersts = [];
      this.$emit("click:clear");
    },
    onSelectSearchFromSuggerst(suggerst) {
      if (suggerst.type == "result") {
        this.onSelectSearch(suggerst.data);
      } else {
        this.p_search = suggerst;
        this.model = suggerst;
      }
    },
    onSelectItem(item) {
      if (item.type == "search") {
        this.onSelectSearch(item.data);
      } else if (item.type == "suggerst") {
        this.onSelectSearchFromSuggerst(item.data);
      }
      this.p_model = this.p_search;
    },
    onSelectLocation(location) {
      if (!location) return;
      this.p_search = location.full_name;
    },
    onSelectSearch(item) {
      this.$emit("click:select", item);
    },
  },
};
</script>

<style scoped></style>
